<template>
  <div id="app">
    <div
      class="
        flex
        justify-between
        text-blue
        mx-4
        md:mx-6
        mt-3
        md:mt-4
        text-2xl
        md:text-4xl
      "
    >
      <a href="/" title="Full House">Full House</a>
      <div class="space-x-3 md:space-x-6">
        <a href="https://discord.gg/hNS7H3Bvd9" title="Discord">Discord</a>
        <a href="https://twitter.com/fullhou_se" title="Twitter: @fullhou_se"
          >Twitter</a
        >
      </div>
    </div>
    <div
      class="
        h-screen
        w-screen
        fixed
        inset-0
        pointer-events-none
        flex
        items-center
        justify-center
      "
    >
      <div class="relative -mt-16 md:-mt-3 w-64">
        <img src="./assets/face.svg" class="w-full" />
        <img src="./assets/hand.svg" class="w-full hand" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="css">
@import './css/app.css';

@keyframes hand {
  0% {
    transform: translateX(-3px) rotate(-3deg);
  }
  100% {
    transform: translateX(3px) rotate(3deg);
  }
}

.hand {
  position: absolute;
  top: 2px;
  animation-name: hand;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
}
</style>
